<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    watch: {
      '$route'() {
        // 监听路由变化，并设置页面标题
        document.title = this.$route.meta.title || 'Vue App'
      }
    },
    data() {
      return {

      };
    },
    methods: {
      // logout() {
      //   // 执行退出登录操作，例如调用 Vuex 的 logout 动作
      //   signOut(this.$auth).then(() => {
      //     // Sign-out successful.
      //     this.$store.commit("user/SET_USER_INFO", userInfo) //重置登录信息
      //     this.$store.commit("user/SET_TOKEN", "")//重置token信息
      //     this.$store.commit("user/SET_UID", "")//重置token信息
      //     this.$store.commit("user/SET_LOGIN_INFO", null) //重置登录信息
      //   }).catch((error) => {
      //     // An error happened.
      //   });
      //   this.$router.push('/login');
      // }
      // getToken() {

      //   this.$messaging.getToken({ vapidKey: "BFDwAWBdlRAYLSDdyLhYlZqQoIOXyx9RI-SlaMlqgHQ66wLoWML-mueJFoWyITxmskupXlYR4ZqCfAh4XJGa6Nw", })
      //     .then((currentToken) => {
      //       if (currentToken) {
      //         // ...
      //         console.log(currentToken);
      //       }
      //       else {
      //         console.log("No registration token available. Request permission to generate one.");
      //       }
      //     })
      //     .catch((err) => {
      //       console.log("An error occurred while retrieving token. ", err);
      //     });
      // },

      // getMessage() {

      //   this.$messaging.onMessage((payload) => {
      //     console.log('Message received. ', payload);
      //     // ...
      //   });
      // }

    },
    mounted() {
      console.log('API URL:', process.env.VUE_APP_API_URL);
      // let idleTimer;
      // // 监听用户的鼠标移动、键盘按键和滚动事件
      // window.addEventListener('mousemove', resetTimer);
      // window.addEventListener('keypress', resetTimer);
      // window.addEventListener('scroll', resetTimer);

      // function resetTimer() {
      //   clearTimeout(idleTimer);
      //   idleTimer = setTimeout(() => {
      //     // 触发自动退出登录逻辑
      //     this.logout();
      //   }, 600000); // 设置自动退出时间，单位为毫秒
      // }

      // 设置用户操作超时时间，单位为毫秒
      // const timeoutDuration = 1 * 24 * 60 * 60 * 1000; // 3天
      // let timeoutId = null;
      // const resetTimeout = () => {
      //   // 清除之前的定时器
      //   clearTimeout(timeoutId);
      //   // 设置新的定时器
      //   timeoutId = setTimeout(() => {
      //     // 执行退出登录逻辑
      //     this.logout();
      //   }, timeoutDuration);
      // };
      // // 监听用户操作事件
      // window.addEventListener('mousemove', resetTimeout);
      // window.addEventListener('mousedown', resetTimeout);
      // window.addEventListener('keydown', resetTimeout);
      // window.addEventListener('scroll', resetTimeout);

    },
    created() {
      // 注册全局错误处理函数
      this.$on('error', (error) => {
        console.error(error)
        // 跳转到错误页面
        this.$router.push('/error')
      })
    }
  };
</script>

<style lang="less">
  html,
  body,
  #app {
    width: 100%;
    height: 100%;
  }
</style>