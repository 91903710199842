const state = {
    userInfo: null,//用户信息
    userInfomation: null,//用户信息
    profileInfomation: null,//用户信息
    loginInfo: null,//登录信息
    token: "",//token信息
    firebasetoken: "",//token信息
    uid: "",//用户id
    isLoggedIn: false,
    notifications: [],
    downloadingList: [],
    downloadData: []
}

const mutations = {
    SET_USER_INFO: (state, value) => {//新增用户信息
        console.log('用户信息', value)
        state.userInfo = value
    },
    SET_USER_INFOMATION: (state, value) => {//新增用户信息
        console.log('用户信息', value)
        state.userInfomation = value
        state.isLoggedIn = true
    },
    CLEAR_USER_INFOMATION: (state) => {//新增用户信息
        console.log('用户信息', state)
        state.userInfomation = null
        state.isLoggedIn = false
    },
    SET_PROFILE_INFOMATION: (state, value) => {//新增用户信息
        console.log('用户信息', value)
        state.profileInfomation = value
    },
    SET_LOGIN_INFO: (state, value) => {//新增用户登录信息，
        console.log('登录信息', value)
        state.loginInfo = value
    },
    SET_TOKEN: (state, value) => {
        console.log('token信息', value)
        state.token = value
    },
    SET_FIREBASETOKEN: (state, value) => {
        console.log('token信息', value)
        state.firebasetoken = value
    },

    SET_UID: (state, value) => {
        console.log('uid信息', value)
        state.uid = value
    },
    UPDATE_NOTIFICATION(state, data) {
        // 确保 state.notifications 是一个有效的数组
        if (!Array.isArray(state.notifications)) {
            console.warn('Notifications should be an array');
            state.notifications = [];
        }

        // 过滤掉 null 值和其他无效项
        const validNotifications = state.notifications.filter(item => item && typeof item === 'object');

        // 更新或添加通知
        const existingIndex = validNotifications.findIndex(
            item => item?.uuid === data?.uuid && item?.updateType === data?.updateType
        );

        if (existingIndex > -1) {
            // 如果通知存在且 updateType 匹配，更新它
            validNotifications[existingIndex] = { ...validNotifications[existingIndex], ...data };
        } else {
            // 如果通知不存在，添加它
            // validNotifications.push(data);
            validNotifications.unshift(data);
        }

        const dowmloadIndex = state.downloadingList.findIndex(
            item => item?.uuid === data.uuid && item?.updateType === data.updateType
        );

        // 过滤掉 null 值和其他无效项
        const validDowmloadingList = state.downloadingList.filter(item => item && typeof item === 'object');


        // 更新下载中的数据
        if (dowmloadIndex > -1) {
            // 如果通知存在且 updateType 匹配，更新它
            
            if (data.progress > -1) {
                validDowmloadingList[dowmloadIndex] = { ...validDowmloadingList[dowmloadIndex], ...data };
            } else {
                validDowmloadingList.splice(dowmloadIndex, 1);
            }
        } else {
            // 如果通知不存在，添加它
            validDowmloadingList.unshift(data);
        }

        if (data.progress == -1) {
            console.log('Progress is -1 for data:', data.uuid, validDowmloadingList);
        }

        // 更新 state.notifications
        state.notifications = validNotifications;
        state.downloadingList = validDowmloadingList;

        //   console.log('notifications',state.notifications[0].updateType, state.notifications[0].progress, state.notifications[0].uuid);
        //   console.log('notifications',state.notifications[1].updateType, state.notifications[1].progress, state.notifications[1].uuid);

    },
    SET_DOWNLOAD_LIST(state, list) {
        state.downloadData = list;
        console.log("state.downloadData:", state.downloadData)
    },
    CLEAR_NOTIFICATIONS(state) {
        state.notifications = [];
    },
    CLEAR_DOMNLOADING_LIST(state) {
        state.downloadingList = [];
    },

    SET_DOMNLOADING_LIST(state, data) {
        state.downloadingList = data;
    }
}
const actions = {
    showData({ state }) {
        // Handle showing data logic, e.g., displaying notifications
        console.log('Show Data Action Triggered');
    },
    updateNotification({ commit }, data) {
        commit('UPDATE_NOTIFICATION', data);
    },

    logout({ commit }) {
        commit('CLEAR_USER_INFOMATION');
        // 其他登出操作
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions

}