/**
 * 简体中文
 */
import route from './route';
import router from './router';
import layout from './layout';
import login from './login';
import list from './list';
import brand from './brand';
import app from './app';

export default {
  route,
  router,
  layout,
  login,
  list,
  brand,
  app,
};
