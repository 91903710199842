/* 主框架 */
export default {
  projectManagement: '项目管理',
  appUserManagement: 'APP用户管理',
  EndUserManagement: '终端用户管理',
  serverPermissions: '服务器权限',
  downloadCenter: '下载中心',
  operationRecord: '操作记录',
  AdministrativeRights: '管理权限',
  logout:'退出登录',
  systemName:'Gigaboyz管理系统',
  profile:"个人资料",
  index:'首页',
  typeManagement: '类目管理',
  appManagement: 'APP管理',
};
