/* 登录页面 */
export default {
  title: '登录',
  username: '请输入用户名',
  password: '请输入密码',
  emailName: '请输入邮箱或用户名',
  code: '请输入验证码',
  codes: '验证码',
  remember: '记住密码',
  forget: '忘记密码',
  login: '登录',
  loading: '登录中',
  registerTitle: '注册用户',
  register: '注册',
  welcome:'欢迎使用 GigaBoyz 管理系统',
  email:'账号',
  passwordLabel:'密码',
  confirmpassword:'确认密码',
  user:'用户名',
  loginSuccess:'登录成功',
  loginErr:'登录失败',
  registerSuccess:'注册成功',
  registerError:'注册失败',
  putNewPass:'请输入新的密码',
  reputNewPass:'请再次输入密码',
  getCode:'发送验证码',
  sended:'已发送',
  back:'返回登录',
  reset:'找回密码',
  different:'两次输入密码不一致',
  changeSuccess:'修改成功',
  sendSuccess:'找回密码邮件已发送至您的注册邮箱，请查收!',
  registered:'此邮箱已被注册',
   noregistered:'该邮箱未被注册',
  passLength:'密码只能使用8-20个字符',
  correctedEmail:'请输入正确的邮箱',
  wrongEmail:'不能使用此邮箱域名注册账户',
  wrongName:'请输入英文/数字/中划线',
  userLength:'用户名只能使用4-20个字符',
  wrongPass:'密码错误',
};
