/* 主框架 */
export default {
  breadBrand:'品牌',
  brandName:'品牌名称',
  brandNameInput:'请输入品牌名称',
  project:'项目',
  projectName:'项目名称',
  projectNameInput:'请输入项目名称',
  addBrand:'添加品牌',
  projectNum:'项目总数',
  softwareNum:'软件总数',
  currentlocal:'当前位置',
  index:'首页',
  creatTime:'创建时间',
  startTime:'开始日期',
  endTime:'结束日期',
  to:'至',
  search:'搜索',
  reset:'重置',
  columnSetting:'列设置',
  updateTime:'更新时间',
  moreActions:'更多操作',
  edit:'编辑',
  details:'详情',
  delete:'删除',
  push:'推送',
  subbrandNum:'子品牌数',
  platform:'适用平台',
  carLabel:'适配车型',
  description:'描述',
  desInput:'请输入描述',
  cancel:'取消',
  save:'保存',
  editBrand:'修改品牌',
  confirmTitle:'提示',
  confirmContent:'确定要删除当前选中的品牌吗？',
  confirmprojectContent:'确定要删除当前选中的项目吗？',
  confirmfileContent:'确定要删除当前选中的文件吗？',
  confirm:'确定',
  addProject:'添加项目',
  choose:'请选择',
  editProject:'修改项目',
  deleteSuc:'删除成功',
  deleteFal:'删除失败',
  addSuc:'新增成功',
  addFal:'新增失败',
  updateSuc:'更新成功',
  updateFal:'更新失败',
  exitBrand:'该品牌名已存在，请重新输入',
  exitProject:'该项目名已存在，请重新输入',
  brandVali:'品牌名称仅由英文字母，数字以及中横线组成,且首字母须大写',
  projectVali:'项目名称仅由英文字母，数字以及中横线组成,且首字母须大写',
  pushing:'正在推送中',
  pushSuc:'推送成功',
  pushFal:'推送失败',
  Asia:'Asia',
  China:'China',
  Korea:'Korea',
  Japan:'Japan',
  Singapore:'Singapore',
  Malaysia:'Malaysia',
  Europe:'Europe',
  UK:'United  kingdom',
  Germany:'Germany',
  France:'France',
  Netherlands:'Netherlands',
  NorthAmerica:'North America',
  US:'United States',
  Canada:'Canada',
  Mexico:'Mexico',
  Vietnam:'Vietnam',
  input:'请输入内容',
  file:'文件',
  softName:'软件名称',
  softNames:'软件名称:',
  softnameInput:'请输入软件名称',
  softwareType:'软件类型',
  stand:'Standard Ver',
  special:'Special Ver',
  updateType:'升级类型',
  subProject:'子项目',
  subProjects:'子项目:',
  download:'下载次数',
  subprojectInput:'请输入子品牌',
  hwVersion:'硬件版本',
  hwInput:'请输入硬件版本',
  softVersion:'软件版本',
  softwareInput:'请输入软件版本',
  saleCountry:'销售国家',
  expand:'展开',
  pack:'收起',
  upload:'上传文件',
  downloading:'当前有 1 个软件正在被下载',
  more:'查看更多>> ',
  pushStatus:'推送状态',
  notPushed:'未推送',
  pushed:'已推送',
  pushings:'推送中',
  uploadSoft:'上传软件',
  confirmMessage:'上传前请先填写软件的详细信息',
  chooseFile:'选择文件',
  fill:'填写',
  fillSucess:'填写成功',
  softwareSize:'软件大小:',
  softwareSizes:'软件大小',
  exportCountry:'出口国家',
  exportCountrys:'出口国家:',
  ApplicableModels:'适配车型:',
  ApplicableModelss:'适配车型',
  chip:'芯片:',
  chips:'芯片',
  newField:'新增字段',
  softwareDes:'软件描述',
  softwareDess:'软件描述:',
  finish:'完成',
  editFields:'修改',
  uploadTime:'上传时间:',
  uploadTimes:'上传时间',
  downloadNum:'下载次数:',
  downloadNums:'下载次数',
  downloadCenter:'下载中心',
  fromWeek:'自上周以来',
  trend:'趋势',
  downloadPlatform:'下载平台分布',
  January:'1月',
  February:'2月',
  March:'3月',
  April:'4月',
  May:'5月',
  June:'6月',
  July:'7月',
  August:'8月',
  Sep:'9月',
  October:'10月',
  November:'11月',
  December:'12月',
  userName:'用户名称',
  downloadProgress:'下载进度',
  downloadTime:'下载时间',
  uploadSuc:'上传成功',
  uploading:'正在上传中',
  uploadErr:'上传失败',
  breakpointResume:'断点重续!',
  skip:'文件已经存在，跳到下一个文件上传',
  noneFilled:'没有填写好完整信息',
  maxFile:'最大只能选择3个文件上传',
  maxAdd:'最多可新增12条!',
  deleteUser:'确定要删除此内容吗?',
  editSoftinfo:'修改软件信息',
  editedTime:'更新时间:',
  userNums:'用户数量',
  downloadTotal:'总下载量',
  softwareDetails:'软件详情',
  downloadArea:'下载地区排行',
  phoneDownload:'手机下载排行',
  perhourDownload:'最近1天下载次数',
  year:'年',
  month:'月',
  day:'日',
  softwareNO:'{name}型号:',
  keywords:'请输入搜索关键字',
  please_input:"请输入",
  software_require:"首字母以大写字母V开头或者数字",
  input_newSubmodel:"请输入新的子项目",
  submodelVali:'仅由英文字母，数字以及中横线组成,且首字母须大写',
  submodelError:'该子项目已存在，请重新输入',
  input_newUpdateType:"请输入新的升级类型",
  updateypeError:'该升级类型已存在，请重新输入',
  input_newmodel:"请输入新的型号",
  add_submodel:"添加子项目",
  add_updatetype:"添加升级类型",
  add_model:"添加型号",
  choose_year:"请选择年份",
  choose_date:"请选择日期",
  nodata:"暂无数据",
  addURL:"添加URL",
};
