/* 主框架 */
export default {
  projectManagement: 'Project',
  appUserManagement: 'APP User',
  EndUserManagement: 'User',
  serverPermissions: 'Permissions',
  downloadCenter: 'Download',
  operationRecord: 'Operation',
  AdministrativeRights: 'Administrative',
  logout:'Logout',
  systemName:'Gigaboyz Server Management System',
  profile:"Personal Information",
  index:'Dashboard',
  typeManagement: 'Type',
  appManagement: 'APP',
};