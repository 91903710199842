/* 主框架 */
export default {
  index: '首页',
  brand: '品牌',
  project: '项目',
  software: '软件',
  addURL: '添加 URL',
  downloadCenter: '下载中心',
  downloadArea: '下载地区',
  nodata:'暂无数据',
  uploadSoftware:'上传软件',
  type:'类目',
  AppManagement:'APP管理',
  activatedDetails:'已激活详情',
  addApp:'新增APP',
  device:'设备',
  deviceManagement:'设备管理',
  addDevice:'新增设备',
  uploadArticle:'上传文章',
  addTheme:'添加主题',
  editTheme:'编辑主题',
  editArticle:'编辑文章',
  softwareDetails:'软件详情',
  downloadCenter:'下载中心',
  userRights:'用户权限',
  AppUserManagement:'APP用户管理',
  operationCodes:'操作记录',
  managePermissions:'管理权限',
  endUserManagement:'终端用户管理',
  userManagement:'用户管理',
  personalInformation:'个人资料',
  register:'注册',
  forgetPassword:'忘记密码',
  login:'登录',
  errors:'错误'
};
