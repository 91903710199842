import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);
const router = new Router({
	mode: 'history',
	routes: [{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/common',
		component: () => import('../common/Base.vue'),
		meta: {
			title: '公共部分', breadcrumb: false
		},
		children: [
			{
				path: '/tab',
				component: () => import('../views/Tab.vue'),
				meta: {
					title: 'tab选项卡'
				}
			},
			{
				path: '/table',
				component: () => import('../views/Table.vue'),
				meta: {
					title: '表格'
				}
			},
			{
				path: '/project',
				component: () => import('../views/file/project/index.vue'),
				meta: {
					title: '项目 | Gigaboyz',
					name: '项目 | Gigaboyz',
					breadcrumb: (to) => {
						const brandName = to.query.brandName;
						return [
							{ name: 'Dashboard', path: '/home' },
							{ name: brandName, path: '/brand' },
							{ name: 'Project', path: to.fullPath },
						];
					}


					

					// $t('brand.brandNameInput')
				}
			},
			{
				path: '/fileTable',
				component: () => import('../views/file/fileTable/index.vue'),
				meta: {
					title: '软件 | Gigaboyz',
					breadcrumb: (to) => {
						const brandName = to.query.brandName;
						const brandId = to.query.brandId;
						const projectName = to.query.projectName; // 您可以从路由参数或其他地方获取项目名称
						return [
							{ name: 'Dashboard', path: '/home' },
							{ name: brandName, path: '/brand' },
							{ name: projectName, path: `/project?brandName=${brandName}&id=${brandId}` },
							{ name: 'Software', path: to.fullPath }
						];
					}
				}
			},
			{
				path: '/typeTable',
				component: () => import('../views/typeManagement/typeTable/index.vue'),
				meta: {
					title: '类目 | Gigaboyz',
					breadcrumb: (to) => {
						const domainName = to.query.domainName;
						const id = to.query.id;
						const typeName = to.query.typeName; // 您可以从路由参数或其他地方获取项目名称
						return [
							{ name: '首页', path: '/home' },
							{ name: domainName, path: `/type` },
							{ name: typeName, path: to.fullPath }
						];
					}
				}
			},
			{
				path: '/application',
				component: () => import('../views/application/index.vue'),
				meta: {
					title: 'APP | Gigaboyz',
					breadcrumb: (to) => {
						return [
							{ name: '首页', path: '/home' },
							{ name: 'APP管理', path: to.fullPath },
						];
					}
				}
			},
			{
				path: '/device',
				component: () => import('../views/application/device/index.vue'),
				meta: {
					title: 'APP | Gigaboyz',
					breadcrumb: (to) => {
						const appName = to.query.appName.replace(/\s/g, '+');
						console.log(appName,"appName")
						return [
							{ name: '首页', path: '/home' },
							{ name: 'APP管理', path: `/application` },
							{ name: appName, path: to.fullPath },
						];
					}
				}
			},
			{
				path: '/user',
				component: () => import('../views/application/user/index.vue'),
				meta: {
					title: 'APP | Gigaboyz',
					breadcrumb: (to) => {
						const appName = to.query.appName.replace(/\s/g, '+');
						const appToName = encodeURIComponent(to.query.appName);
						const appUid = to.query.appUid;
						const projectName = to.query.projectName;
						return [
							{ name: '首页', path: '/home' },
							{ name: 'APP管理', path: `/application` },
							{ name: appName, path: `/device?appUid=${appUid}&appName=${appToName}` },
							{ name: '已激活详情', path: `/activatedApp?appUid=${appUid}&appName=${appToName}` },
							{ name: projectName, path: to.fullPath },
						];
					}
				}
			},
			{
				path: '/activatedApp',
				component: () => import('../views/application/user/activatedApp.vue'),
				meta: {
					title: 'APP | Gigaboyz',
					breadcrumb: (to) => {
						const appName =to.query.appName.replace(/\s/g, '+');
						const appToName = encodeURIComponent(to.query.appName);
						const appUid = to.query.appUid;
						console.log(appName,"appName")
						return [
							{ name: '首页', path: '/home' },
							{ name: 'APP管理', path: `/application` },
							{ name: appName, path: `/device?appUid=${appUid}&appName=${appToName}` },
							{ name: '已激活详情', path: to.fullPath },
						];
					}
				}
			},
			{
				path: '/addApp',
				component: () => import('../views/application/components/app-add.vue'),
				meta: {
					title: 'APP | Gigaboyz',
					breadcrumb: (to) => {
						const appName = to.query.appName;
						const appUid = to.query.appUid;
						const isEditMode = appUid !== undefined;
						return [
							{ name: '首页', path: '/home' },
							{ name: 'APP管理', path: `/application` },
							{ name: isEditMode ? '编辑APP' : '新增APP', path: to.fullPath },
						];
					}
				}
			},
			{
				path: '/addDevice',
				component: () => import('../views/application/device/components/device-edit.vue'),
				meta: {
					title: '设备 | Gigaboyz',
					breadcrumb: (to) => {
						const appName = to.query.appName;
						const appUid = to.query.appUid;
						const id = to.query.id;
						const isEditMode = id !== undefined;
						return [
							{ name: '首页', path: '/home' },
							{ name: '设备管理', path: `/device?appUid=${appUid}&appName=${appName}` },
							{ name: isEditMode ? '编辑设备' : '新增设备', path: to.fullPath },
						];
					}
				}
			},
			{
				path: '/upload',
				component: () => import('../views/file/fileTable/upload.vue'),
				meta: {
					title: '上传软件 | Gigaboyz',
					breadcrumb: (to) => {
						const brandName = to.query.brandName;
						const brandId = to.query.brandId;
						const id = to.query.projectId;
						const projectName = to.query.projectName; // 您可以从路由参数或其他地方获取项目名称
						return [
							{ name: '首页', path: '/home' },
							{ name: brandName, path: '/brand' },
							{ name: projectName, path: `/project?brandName=${brandName}&id=${brandId}` },
							{ name: '软件', path: `/fileTable?brandName=${brandName}&projectName=${projectName}&brandId=${brandId}&id=${id}` },
							{ name: '上传软件', path: to.fullPath }
						];
					}
				},
			},
			{
				path: '/addSoftware',
				component: () => import('../views/file/fileTable/uploadUrl.vue'),
				meta: {
					title: 'Upload Software | Gigaboyz',
					breadcrumb: (to) => {
						const brandName = to.query.brandName;
						const brandId = to.query.brandId;
						const id = to.query.projectId;
						const projectName = to.query.projectName; // 您可以从路由参数或其他地方获取项目名称
						return [
							{ name: '首页', path: '/home' },
							{ name: brandName, path: '/brand' },
							{ name: projectName, path: `/project?brandName=${brandName}&id=${brandId}` },
							{ name: '软件', path: `/fileTable?brandName=${brandName}&projectName=${projectName}&brandId=${brandId}&id=${id}` },
							{ name: '上传软件', path: to.fullPath }
						];
					}
				},
			},
			{
				path: '/addArticle',
				component: () => import('../views/typeManagement/typeTable/uploadUrl.vue'),
				meta: {
					title: '上传文章 | Gigaboyz',
					breadcrumb: (to) => {
						const domainName = to.query.domainName;
						const typeName = to.query.typeName;
						const id = to.query.id;
						const type = to.query.type;
						return [
							{ name: '首页', path: '/home' },
							{ name: type, path: '/type' },
							{ name: typeName, path: `/typeTable?domainName=${domainName}&id=${id}&type=${type}&typeName=${typeName}` },
							{ name: '添加主题', path: to.fullPath }
						];
					}
				},
			},
			{
				path: '/editArticle',
				component: () => import('../views/typeManagement/typeTable/editTheme.vue'),
				meta: {
					title: '编辑文章 | Gigaboyz',
					breadcrumb: (to) => {
						const domainName = to.query.domainName;
						const typeName = to.query.typeName;
						const id = to.query.id;
						const type = to.query.type;
						const titleId = to.query.titleId; // 添加titleId的获取
						const finalName = titleId ? '编辑主题' : '新增主题';
						return [
							{ name: '首页', path: '/home' },
							{ name: type, path: '/type' },
							{ name: typeName, path: `/typeTable?domainName=${domainName}&id=${id}&type=${type}&typeName=${typeName}` },
							{ name: finalName, path: to.fullPath }
						];
					}
				},
			},
			{
				path: '/fileIndex',
				component: () => import('../views/file/fileTable/fileIndex/index.vue'),
				meta: {
					title: 'Software Details | Gigaboyz',
					breadcrumb: (to) => {
						const brandName = to.query.brandName;
						const brandId = to.query.brandId;
						const id = to.query.id;
						const projectName = to.query.projectName; // 您可以从路由参数或其他地方获取项目名称
						const fileName = to.query.fileName;
						return [
							{ name: '首页', path: '/home' },
							{ name: brandName, path: '/brand' },
							{ name: projectName, path: `/project?brandName=${brandName}&id=${brandId}` },
							{ name: '软件', path: `/fileTable?brandName=${brandName}&projectName=${projectName}&brandId=${brandId}&id=${id}` },
							{ name: fileName, path: to.fullPath }
						];
					}
				},
			},
			{
				path: '/fileDownload',
				component: () => import('../views/file/fileTable/fileIndex/downloadCenter.vue'),
				meta: {
					title: 'Download Center | Gigaboyz',
					breadcrumb: (to) => {
						const brandName = to.query.brandName;
						const brandId = to.query.brandId;
						const id = to.query.projectId;
						const projectName = to.query.projectName; // 您可以从路由参数或其他地方获取项目名称
						return [
							{ name: 'Dashboard', path: '/home' },
							{ name: brandName, path: '/brand' },
							{ name: projectName, path: `/project?brandName=${brandName}&id=${brandId}` },
							{ name: 'Software', path: `/fileTable?brandName=${brandName}&projectName=${projectName}&brandId=${brandId}&id=${id}` },
							{ name: 'Download Center', path: to.fullPath }
						];
					}
				},
			},
			{
				path: '/permissions',
				component: () => import('../views/permissions/index.vue'),
				meta: {
					title: '用户权限 | Gigaboyz', breadcrumb: true, name: '用户权限'
				}
			},
			{
				path: '/download',
				component: () => import('../views/downloadCenter/index.vue'),
				meta: {
					title: '下载中心 | Gigaboyz', breadcrumb: true, name: '下载中心'
				}
			},
			{
				path: '/apps',
				component: () => import('../views/appManagement/index.vue'),
				meta: {
					title: 'APP用户管理 | Gigaboyz', requiresAuth: true,
					breadcrumb: [{ name: '首页', path: '/home' }, { name: 'APP用户管理', path: '/apps' }]
				}

			},
			{
				path: '/home',
				requiresAuth: true,
				component: () => import('../views/home/index.vue'),
				name: 'Dashboard',
				meta: {
					title: '首页 | Gigaboyz',
					breadcrumb: (to) => {
						return [
							{ name: "Dashboard", path: '/home' },
						];
					}
				},
			},
			{
				path: '/operations',
				component: () => import('../views/handlerRecord/index.vue'),
				meta: {
					title: '操作记录 | Gigaboyz', breadcrumb: true, name: '操作记录'
				}
			},
			{
				path: '/managePermissions',
				component: () => import('../views/managePermissions/index.vue'),
				meta: {
					title: '管理权限', breadcrumb: true, name: '管理权限'
				}
			},
			{
				path: '/users',
				requiresAuth: true,
				component: () => import('../views/userManagement/index.vue'),
				meta: {
					title: '终端用户管理 | Gigaboyz',
					breadcrumb: [{ name: '首页', path: '/home' }, { name: '终端用户管理', path: '/users' }]
				}
			},
			{
				path: '/Test1',
				component: () => import('../views/Test/index.vue'),
				meta: {
					title: '测试'
				}
			},
			{
				path: '/test2',
				component: () => import('../views/Test/test2.vue'),
				meta: {
					title: '测试'
				}
			},
			{
				path: '/test3',
				component: () => import('../views/Test/test3.vue'),
				meta: {
					title: '测试'
				}
			},
			{
				path: '/brand',
				component: () => import('../views/file/index.vue'),
				meta: {
					title: '品牌 | Gigaboyz', requiresAuth: true,
					breadcrumb: [{ name: 'Dashboard', path: '/home' }, { name: 'Brand', path: '/brand' }]
				}
			},
			{
				path: '/type',
				component: () => import('../views/typeManagement/index.vue'),
				meta: {
					title: '类目 | Gigaboyz', requiresAuth: true,
					breadcrumb: [{ name: '首页', path: '/home' }, { name: '类目', path: '/type' }]
				}
			},
			{
				path: '/user',
				component: () => import('../views/user/index.vue'),
				meta: {
					title: '品牌 | Gigaboyz', requiresAuth: true,
					breadcrumb: [{ name: '首页', path: '/home' }, { name: '用户管理', path: '/user' }]
				}
			},
			{
				path: '/profile',
				component: () => import('../views/profile/index.vue'),
				meta: {
					title: '个人资料 | Gigaboyz', requiresAuth: true,
					breadcrumb: [{ name: '首页', path: '/home' }, { name: '个人资料', path: '/profile' }]
				}
			},
		]
	},
	{
		path: '/newlogin',
		component: () => import('../views/newLogin.vue')
	},
	{
		path: '/register',
		component: () => import('../views/register.vue'),
		meta: {
			title: '注册 | Gigaboyz'
		}
	},
	{
		path: '/forget',
		component: () => import('../views/forget.vue'),
		meta: {
			title: '忘记密码 | Gigaboyz'
		}
	},
	{
		path: '/login',
		component: () => import('../views/login.vue'),
		meta: {
			title: '登录 | Gigaboyz'
		}
	},
	{
		path: '/error',
		component: () => import('../views/Error.vue'),
		meta: {
			title: '错误 | Gigaboyz'
		}
	},
	{
		path: '*',
		component: () => import('../views/404.vue'),
		meta: {
			title: '404 | Gigaboyz'
		}
	},
	{
		path: '/404',
		component: () => import('../views/404.vue'),
		meta: {
			title: '404 | Gigaboyz'
		}
	}
	]
});

export default router
