import Vue from 'vue'
import App from './App.vue'
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import './directive'
import router from './router'
import store from './store/index';
import WebSocketPlugin from './util/websocket';
import WebSocketSingleton from './util/websocket';
console.log('WebSocketSingleton:', WebSocketSingleton);
import ElementUI from 'element-ui';
import '@/assets/css/common.less';
import '@/assets/css/variable.less';
import '@/assets/css/reset.css';
import '@/assets/css/icon.css';
import '@/assets/css/index.css';
import '@/assets/font_3996221_n6cf7k1jpq/iconfont';
import 'element-ui/lib/theme-chalk/index.css';
import 'ele-admin/es/style/icon.scss';
import VueSSE from 'vue-sse';
import axios from 'axios';
import EleAdmin from 'ele-admin';
import * as echarts from 'echarts'
// import { initializeApp } from "firebase/app";
import i18n from './i18n';
import locale from 'element-ui/lib/locale'
locale.i18n((key, value) => i18n.t(key, value)); // Set Element UI to use Vue I18n
// import DatePicker from 'element/lib/date-picker'
// Vue.use(DatePicker)
// import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// const firebaseConfig = {
//   apiKey: "AIzaSyAtIaW3jktknbhNoBhpjqThe3hSMNm5fBU",
//   authDomain: "gigaboyzmicroservice.firebaseapp.com",
//   projectId: "gigaboyzmicroservice",
//   storageBucket: "gigaboyzmicroservice.appspot.com",
//   messagingSenderId: "227095012797",
//   appId: "1:227095012797:web:8453826e687c48ffb3ded4",
//   measurementId: "G-TBVQDPC9KB"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
// const auth = getAuth();
// Vue.prototype.$messaging = messaging;
// Vue.prototype.$auth = auth;
// Vue.prototype.$store = store;
// console.log(messaging, 'messaging')
// Vue.use(VueSSE, {
//   format: 'json',
//   polyfill: true,
//   url: 'http://vehicle.gigabozz.com/upgrade/other/server/event/source?fb_uid=12334&brandName=Kenwood&projectName=DMX125&softwareUrl=http://vehicle.gigabozz.com/vehicle/download/JVC/DMX125/DMX125/APP/FC:4B:BC:D4:FA:AF/880fe514-29d6-4274-a423-127e1b718c13/hotspot/vehicle/H01/DMX125_10.zip&updateMethod=false',
//   withCredentials: true,
// });

// isSupported()
//   .then((isAvailable) => {
//     if (isAvailable) {
//       console.log(isAvailable, 'isAvailable')
//       // ...
//     }
//   })
//   .catch(console.error);


// getToken(messaging, { vapidKey: 'BFDwAWBdlRAYLSDdyLhYlZqQoIOXyx9RI-SlaMlqgHQ66wLoWML-mueJFoWyITxmskupXlYR4ZqCfAh4XJGa6Nw' }).then((currentToken) => {
//   if (currentToken) {
//     console.log('currentToken', currentToken);
//     store.commit("user/SET_FIREBASETOKEN", currentToken)
//   } else {
//     console.log('No registration token available. Request permission to generate one.');

//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);

// });

Vue.filter('formatDate', function (value) {
  // console.log(value, 'value')
  const date = value + 'Z'
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(timeZone, 'timeZone');
  const localTime = momentTimezone.tz(date, timeZone).format('YYYY-MM-DD HH:mm:ss');
  // console.log(localTime, 'localTime');
  return localTime;
});


import $ from 'jquery';
Vue.prototype.$ = $
Vue.config.productionTip = false

Vue.use(EleAdmin);
// Use the WebSocket plugin
// Vue.use(WebSocketPlugin);
console.log(WebSocketPlugin,'WebSocketPlugin'); 
console.log('WebSocketSingleton:', WebSocketSingleton); //
// const wsInstance = WebSocketSingleton.getInstance('wss://upgrade.gigabozz.com/ws/progress');
const wsInstance = WebSocketSingleton.getInstance('wss://upgrade.gigabozz.com/ws/progress', store);
Vue.prototype.$webSocket = wsInstance;
// Vue.use(WebSocketPlugin, 'wss://upgrade.gigabozz.com/ws/progress');

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.prototype.$axios = axios; // axios不能使用use
Vue.prototype.$echarts = echarts;
Vue.prototype.$sse = VueSSE;


// 使用router.beforeEach注册一个全局前置守卫，对路由进行权限跳转
// router.beforeEach((to, from, next) => {

//   // 未匹配到路由时 跳转到error页面
//   if (0 === to.matched.length) {

//     next('/error');
//     return false;
//   }

//   // const role = localStorage.getItem('username');
//   let uid = store.state.user.uid
//   if (!uid && to.path !== '/login') {

//     next('/login');
//   } else if (to.meta.permission) {

//     // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
//     // uid === 'admin' ? next() : next('/error');
//   } else {

//     // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
//     if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {

//       Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
//         confirmButtonText: '确定'
//       });
//     } else {

//       next();
//     }
//   }
// });

// 导航守卫
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(route => route.meta.requiresAuth);
//   if (requiresAuth) {
//     // 需要登录验证的页面
//     onAuthStateChanged(auth, (user) => {
//       if (user) {
//         // 检查令牌是否过期
//         const now = new Date().getTime();
//         const expirationTime = this.$store.state.user.userInfomation.token_expiration;
//         if (now > expirationTime) {
//           // 令牌过期，清除用户登录状态并跳转到登录页面
//           signOut(auth).then(() => {
//             store.commit("user/SET_USER_INFO", userInfo) //重置登录信息
//             store.commit("user/SET_TOKEN", "")//重置token信息
//             store.commit("user/SET_UID", "")//重置token信息
//             localStorage.removeItem('user');
//             store.commit("user/SET_LOGIN_INFO", null) //重置登录信息
//             this.$store.commit("user/SET_USER_INFOMATION", null) //重置登录信息
// 					this.$router.push('/login');
//             router.push('/login');
//           }).catch((error) => {
//             console.log('Sign out error', error);
//           });
//         } else {
//           next();
//         }
//         // 用户已登录，继续路由跳转
//       } else {
//         // 用户未登录，跳转到登录页面
//         next('/login');
//       }
//     });
//   } else {
//     // 不需要登录验证的页面，直接继续路由跳转
//     next();
//   }
// });

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
