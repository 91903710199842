/* 登录页面 */
export default {
  title: 'Login',
  registerTitle: 'Register',
  username: 'please input username',
  emailName: 'please input email or username',
  password: 'please input password',
  code: 'please input code',
  codes: 'Code',
  remember: 'remember',
  forget: 'forget',
  login: 'login',
  register: 'register',
  loading: 'loading',
  welcome:'Welcome to use GigaBoyz Server Management System',
  email:'Email',
  passwordLabel:'Password',
  loginSuccess:'Log in successful',
  registerSuccess:'Sign in successful',
  registerError:'Sign in failed',
  loginErr:'Log in failed',
  user:'Username',
  putNewPass:'Please enter new password',
  reputNewPass:'Reenter password',
  getCode:'Get code',
  sended:'Code has been send',
  back:'Back',
  reset:'Reset password',
  different:'Those password do not match',
  changeSuccess:'Reset passwrod successful',
  sendSuccess:'Please check the code on your email!',
  confirmpassword:'Confirm Password',
  registered:'This email is already signed up',
   noregistered:'This email does not register. ',
  passLength:'8-20 characters is required.',
  correctedEmail:'Enter a valid email address',
  wrongEmail:'This email domain is not allowed to be used to sign up',
  wrongName:'Only letters, numbers, and hyphen can be used.',
  userLength:'4-20 characters is required.',
  wrongPass:'Password is incorrect',
};
