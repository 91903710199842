/* 主框架 */
export default {
  breadBrand:'Brand',
  brandName:'Brand',
  brandNameInput:'Please enter brand name',
  project:'Project',
  projectName:'Project',
  projectNameInput:'Please enter project name',
  addBrand:'Add Brand',
  projectNum:'Projects',
  softwareNum:'Software',
  currentlocal:'Current',
  index:'Home',
  creatTime:'Created',
  startTime:'Start Date',
  endTime:'End Date',
  to:'to',
  search:'Search',
  reset:'Reset',
  columnSetting:'Columns',
  updateTime:'Updated',
  moreActions:'More',
  edit:'Edit',
  details:'Details',
  delete:'Delete',
  push:'Push',
  subbrandNum:'Sub Projects',
  platform:'Platform',
  carLabel:'Applicable',
  description:'Info',
  desInput:'Please enter description',
  cancel:'Cancel',
  save:'Save',
  editBrand:'Edit Brand',
  confirmTitle:'Alert',
  confirmContent:'Are you sure you want to delete the selected brand?',
  confirmprojectContent:'Are you sure you want to delete the selected project?',
  confirmfileContent:'Are you sure you want to delete the selected file?',
  confirm:'Confirm',
  addProject:'Add Project',
  choose:'Please Select',
  editProject:'Edit Project',
  deleteSuc:'Delete Successful',
  deleteFal:'Delete Failed',
  addSuc:'Add Successful',
  addFal:'Add Failed',
  updateSuc:'Update Successful',
  updateFal:'Update Failed',
  exitBrand:'The brand name already exists, please enter another name',
  exitProject:'The project name already exists, please enter another name',
  brandVali:'Only require English, numbers & dashes, and the first letter must be capitalized',
  projectVali:'Only require English, numbers & dashes, and the first letter must be capitalized',
  pushing:'Pushing',
  pushSuc:'Push Successful',
  pushFal:'Push Failed',
  Asia:'Asia',
  China:'China',
  Korea:'Korea',
  Japan:'Japan',
  Singapore:'Singapore',
  Malaysia:'Malaysia',
  Europe:'Europe',
  UK:'United  kingdom',
  Germany:'Germany',
  France:'France',
  Netherlands:'Netherlands',
  NorthAmerica:'North America',
  US:'United States',
  Canada:'Canada',
  Mexico:'Mexico',
  Vietnam:'Vietnam',
  input:'Please enter content',
  file:'File',
  softName:'Software',
  softNames:'Software:',
  softnameInput:'Please enter email or username',
  softwareType:'Type',
  phoneNumber:'Phone Number',
  registerEmail:'Email',
  facebook:'Facebook',
  google:'Google',
  updateType:'Update',
  subProject:'Sub',
  subProjects:'Sub:',
  download:'Download',
  subprojectInput:'Please enter sub project',
  hwVersion:'APP',
  hwInput:'Please enter APP',
  softVersion:'Registration',
  softwareInput:'Please enter software version',
  saleCountry:'Area',
  expand:'Expand',
  pack:'Collapse',
  upload:'Upload File',
  downloading:' 1 software is presently being downloaded',
  more:'View More>> ',
  pushStatus:'Status',
  notPushed:'Standby',
  pushed:'Pushed',
  pushings:'Pushing',
  uploadSoft:'Upload Software',
  confirmMessage:'Please fill in the detailed information of the software before uploading',
  chooseFile:'Select File',
  fill:'Fill',
  fillSucess:'Filled in successfully',
  softwareSize:'Size:',
  softwareSizes:'Size',
  exportCountry:'Export Country',
  exportCountrys:'EXP Country:',
  ApplicableModels:'Applicable:',
  ApplicableModelss:'Applicable',
  chip:'Chip:',
  chips:'Chip',
  newField:'New Field',
  softwareDes:'Software Info',
  softwareDess:'About this Update:',
  finish:'OK',
  editFields:'Edit',
  uploadTime:'Upload:',
  uploadTimes:'Login',
  downloadNum:'Downloads:',
  downloadNums:'Downloads',
  editedTime:'Updated:',
  downloadCenter:'Download Center',
  fromWeek:'From last week',
  trend:'Trend',
  downloadPlatform:'Download Platform',
  January:'Jan',
  February:'Feb',
  March:'Mar',
  April:'Apr',
  May:'May',
  June:'Jun',
  July:'Jul',
  August:'Aug',
  Sep:'Sep',
  October:'Oct',
  November:'Nov',
  December:'Dec',
  userName:'User',
  downloadProgress:'Download Progress',
  downloadTime:'Download Time',
  uploadSuc:'Upload successful',
  uploading:'Uploading',
  uploadErr:'Upload failed',
  breakpointResume:'Breakpoint resume!',
  skip:'This file already exists, skipping to the next file upload',
  noneFilled:'Incomplete information, please fill in all fields',
  maxFile:'Only 3 files can be selected for upload',
  maxAdd:'Up to 12 new entries can be added!',
  deleteUser:'Delete this content?',
  editSoftinfo:'Edit',
  userNums:'Users',
  downloadTotal:'Downloaded',
  softwareDetails:'Software Details',
  downloadArea:'Downloads By Region',
  phoneDownload:'Mobile Download Charts',
  perhourDownload:'Recent Downloads (Hour)',
  year:'Year',
  month:'Month',
  day:'Day',
  softwareNO:'{name} NO:',
  keywords:'Please input keywords',
  sex: "Gender",
  email: "Email",
  register_way: "Registration",
  device: "Device",
  phone_platform: "Platform",
  app_name: "APP",
  address: "Area",
  login_time: "Recently Login",
  uese_id: "User ID",
  role: "Role",
  phone: "Phone",
  register_time: "Register",
  user_name: "Username",
  birthday: "Birth",
  appManage: "APP User Management",
  normal: "Normal User",
  jingxioashang: "Dealer",
  admin: "Admin",
  adaptive_device: "Device",
  equipment_brand:'Brand',
  device_submodel: "Submodel",
  APP_version: "APP Version",
  mobile_phone_brands: "Phone Brand",
  phone_model: "Phone Model",
  phone_system: "Phone System",
  vehicle_type: "Vehicle Type",
  vehicle_brand: "Vehicle Brand",
  vehicle_model: "Vehicle Model",
  production_Year: "Production Year",
  device_software_information: "Software Info",
  device_hardware_information: "Hardware Info",
  device_update_information: "Update Info",
  male:"Male",
  female:"Female",
  editUser:"Edit User Info",
  device_name:"Device Name:",
  choose_submodel:"Please choose",
  auto_update:"Auto Update",
  content:"Content",
  current_software:"Current SW",
  current_hardware:"Current HW",
  specfic_version:"Specfic Ver",
};
