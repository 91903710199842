// class WebSocketSingleton {
//   constructor(url, store) {
//     if (WebSocketSingleton.instance) {
//       return WebSocketSingleton.instance;
//     }
//     this.url = url;
//     this.store = store;
//     this.ws = null;

//     // Watch for login state changes
//     this.store.watch(
//       (state) => state.user.isLoggedIn,
//       (isLoggedIn) => {
//         if (isLoggedIn) {
//           this.connect();
//         } else {
//           this.disconnect();
//         }
//       }
//     );

//     document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
//     WebSocketSingleton.instance = this;
//   }

//   connect() {
//     if (this.isLoggedIn && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
//       this.ws = new WebSocket(this.url);

//       this.ws.onopen = () => {
//         console.log('WebSocket is connected.');
//         this.sendInitialData();
//       };

//       this.ws.onmessage = (event) => {
//         this.handleMessage(event);
//       };

//       this.ws.onclose = (event) => {
//         console.log('WebSocket is event.', event);
//         console.log('WebSocket is closed now.');
//         // if (event.wasClean&&this.isLoggedIn && document.visibilityState === 'visible') {
//         //   console.log('Connection closed cleanly.');
//         // } else {
//         //   this.reconnect();
//         //   console.error('Connection closed with error. Error code:', event.code);
//         // }
//         if (event.code === 1000) {
//           console.log('Normal closure');
//         } else {
//           console.error('Abnormal closure with code:', event.code);
//           // Handle abnormal closure, e.g., attempt reconnection
//           this.reconnect();
//         }
//         console.log('Close code:', event.code);
//         console.log('Close reason:', event.reason);
//         // if (this.isLoggedIn && document.visibilityState === 'visible') {
//         //   this.reconnect();
//         // }
//       };

//       this.ws.onerror = (error) => {
//         console.error('WebSocket error:', error);
//       };
//     }
//   }

//   sendInitialData() {
//     const downloadId = this.store.state.user.userInfomation.fb_uid;
//     const downloadIdObject = { fb_uid: downloadId };
//     const jsonString = JSON.stringify(downloadIdObject);
//     this.ws.send(jsonString);
//   }

//   async handleMessage(event) {
//     console.log('Message from server:', event.data);
//     const data = JSON.parse(event.data);
//     if (!data.hasOwnProperty('heartbeat')) {
//       data.nowTime = Date.now();
//       console.log('Message from server data:', data);
//       this.store.commit('user/UPDATE_NOTIFICATION', data);
//     }
//     if (data.heartbeat === 1) {
//       // 异步发送心跳包
//       await new Promise(resolve => setTimeout(resolve, 0));
//       this.ws.send(JSON.stringify({ heartbeat: 1 }));
//     }
//   }

//   reconnect() {
//     setTimeout(() => {
//       console.log('Reconnecting WebSocket...');
//       this.connect();
//     }, 5000); // Reconnect after 5 seconds
//   }


//   send(message) {
//     if (this.ws && this.ws.readyState === WebSocket.OPEN) {
//       this.ws.send(message);
//     } else {
//       console.error('WebSocket is not open. ReadyState: ' + this.ws.readyState);
//     }
//   }

//   disconnect() {
//     if (this.ws) {
//       this.ws.close();
//       console.log('WebSocket is disconnect now.');
//       this.ws = null;
//       console.log('WebSocket connection closed.');
//     }
//   }

//   handleVisibilityChange() {
//     console.log("document.visibilityState", document.visibilityState, document);
//     if (document.visibilityState === 'visible') {
//       // If the page is visible and user is logged in, reconnect WebSocket
//       if (this.isLoggedIn && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
//         this.connect();
//         console.log("正常连接", document.visibilityState, document);
//       }
//     } else {
//       // If the page is hidden, disconnect WebSocket
//       if (this.ws && this.ws.readyState === WebSocket.OPEN) {
//         this.disconnect();
//         console.log("正常断开", document.visibilityState, document);
//       }
//     }
//   }

//   get isLoggedIn() {
//     return this.store.state.user.isLoggedIn;
//   }

//   static getInstance(url, store) {
//     if (!WebSocketSingleton.instance) {
//       WebSocketSingleton.instance = new WebSocketSingleton(url, store);
//     }
//     return WebSocketSingleton.instance;
//   }
// }

// export default WebSocketSingleton;
// class WebSocketSingleton {
//   constructor(url, store) {
//     if (WebSocketSingleton.instance) {
//       return WebSocketSingleton.instance;
//     }
//     this.url = url;
//     this.store = store;
//     this.ws = null;
//     this.heartbeatInterval = 30000; // 30秒发送一次心跳包
//     this.heartbeatTimer = null;

//     // Watch for login state changes
//     this.store.watch(
//       (state) => state.user.isLoggedIn,
//       (isLoggedIn) => {
//         if (isLoggedIn) {
//           this.connect();
//         } else {
//           this.disconnect();
//         }
//       }
//     );

//     document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
//     window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
//     WebSocketSingleton.instance = this;
//   }

//   connect() {
//     if (this.isLoggedIn && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
//       this.ws = new WebSocket(this.url);

//       this.ws.onopen = () => {
//         console.log('WebSocket is connected.');
//         this.sendInitialData();
//         this.startHeartbeat();
//       };

//       this.ws.onmessage = (event) => {
//         this.handleMessage(event);
//       };

//       this.ws.onclose = (event) => {
//         console.log('WebSocket closed. Code:', event.code, 'Reason:', event.reason);
//         this.stopHeartbeat();
//         if (event.code === 1000) {
//           console.log('Normal closure');
//         } else {
//           console.error('Abnormal closure with code:', event.code);
//           this.reconnect();
//         }
//       };

//       this.ws.onerror = (error) => {
//         console.error('WebSocket error:', error);
//         this.stopHeartbeat();
//       };
//     }
//   }

//   sendInitialData() {
//     const downloadId = this.store.state.user.userInfomation.fb_uid;
//     const downloadIdObject = { fb_uid: downloadId };
//     this.ws.send(JSON.stringify(downloadIdObject));
//   }

//   async handleMessage(event) {
//     console.log('Message from server:', event.data);
//     const data = JSON.parse(event.data);
//     if (!data.hasOwnProperty('heartbeat')) {
//       data.nowTime = Date.now();
//       console.log('Message from server data:', data);
//       this.store.commit('user/UPDATE_NOTIFICATION', data);
//     }
//     if (data.heartbeat === 1) {
//       // 异步发送心跳包
//       await new Promise(resolve => setTimeout(resolve, 0));
//       this.ws.send(JSON.stringify({ heartbeat: 1 }));
//     }
//   }

//   reconnect() {
//     setTimeout(() => {
//       console.log('Reconnecting WebSocket...');
//       this.connect();
//     }, 5000); // Reconnect after 5 seconds
//   }

//   send(message) {
//     if (this.ws && this.ws.readyState === WebSocket.OPEN) {
//       this.ws.send(message);
//     } else {
//       console.error('WebSocket is not open. ReadyState: ' + this.ws.readyState);
//     }
//   }

//   disconnect() {
//     if (this.ws) {
//       this.ws.close();
//       console.log('WebSocket is disconnected now.');
//       this.stopHeartbeat();
//       this.ws = null;
//     }
//   }

//   startHeartbeat() {
//     this.heartbeatTimer = setInterval(() => {
//       if (this.ws.readyState === WebSocket.OPEN) {
//         this.ws.send(JSON.stringify({ heartbeat: 1 }));
//       }
//     }, this.heartbeatInterval);
//   }

//   stopHeartbeat() {
//     if (this.heartbeatTimer) {
//       clearInterval(this.heartbeatTimer);
//       this.heartbeatTimer = null;
//     }
//   }

//   handleVisibilityChange() {
//     if (document.visibilityState === 'visible') {
//       if (this.isLoggedIn && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
//         this.connect();
//       }
//     } else {
//       if (this.ws && this.ws.readyState === WebSocket.OPEN) {
//         this.disconnect();
//       }
//     }
//   }

//   handleBeforeUnload(event) {
//     if (this.ws) {
//       this.disconnect(); // Clean up WebSocket connection before unloading
//     }
//   }

//   get isLoggedIn() {
//     return this.store.state.user.isLoggedIn;
//   }

//   static getInstance(url, store) {
//     if (!WebSocketSingleton.instance) {
//       WebSocketSingleton.instance = new WebSocketSingleton(url, store);
//     }
//     return WebSocketSingleton.instance;
//   }
// }

// export default WebSocketSingleton;


// class WebSocketSingleton {
//   constructor(url, store) {
//     if (WebSocketSingleton.instance) {
//       return WebSocketSingleton.instance;
//     }
//     this.url = url;
//     this.store = store;
//     this.ws = null;
//     this.heartbeatInterval = 8000; // 8秒发送一次心跳包
//     this.heartbeatTimer = null;
//     this.heartbeatResponseTimer = null; // 处理心跳回应的定时器
//     this.heartbeatResponseTimeout = 8000; // 8秒内如果没有心跳回应则重连

//     // Watch for login state changes
//     this.store.watch(
//       (state) => state.user.isLoggedIn,
//       (isLoggedIn) => {
//         if (isLoggedIn) {
//           this.connect();
//         } else {
//           this.disconnect();
//         }
//       }
//     );

//     document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
//     window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
//     WebSocketSingleton.instance = this;
//   }

//   connect() {
//     if (this.isLoggedIn && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
//       this.ws = new WebSocket(this.url);

//       this.ws.onopen = () => {
//         console.log('WebSocket is connected.');
//         this.sendInitialData();
//         this.startHeartbeat();
//       };

//       this.ws.onmessage = (event) => {
//         this.handleMessage(event);
//       };

//       this.ws.onclose = (event) => {
//         console.log('WebSocket closed. Code:', event.code, 'Reason:', event.reason);
//         this.stopHeartbeat();
//         this.stopHeartbeatResponseTimer(); // Stop response timer on close
//         if (event.code === 1000) {
//           console.log('Normal closure');
//         } else {
//           console.error('Abnormal closure with code:', event.code);
//           this.reconnect();
//         }
//       };

//       this.ws.onerror = (error) => {
//         console.error('WebSocket error:', error);
//         this.stopHeartbeat();
//         this.stopHeartbeatResponseTimer(); // Stop response timer on error
//       };
//     }
//   }

//   sendInitialData() {
//     const downloadId = this.store.state.user.userInfomation.fb_uid;
//     const downloadIdObject = { fb_uid: downloadId };
//     this.ws.send(JSON.stringify(downloadIdObject));
//   }

//   async handleMessage(event) {
//     console.log('Message from server:', event.data);
//     const data = JSON.parse(event.data);
//     if (data.heartbeat === 1) {
//       // Reset heartbeat response timer when a heartbeat is received
//       this.resetHeartbeatResponseTimer();
//     } else if (!data.hasOwnProperty('heartbeat')) {
//       data.nowTime = Date.now();
//       console.log('Message from server data:', data);
//       this.store.commit('user/UPDATE_NOTIFICATION', data);
//     }
//   }

//   reconnect() {
//     setTimeout(() => {
//       console.log('Reconnecting WebSocket now...');
//       this.connect();
//     }, 5000); // Reconnect after 5 seconds
//   }

//   send(message) {
//     if (this.ws && this.ws.readyState === WebSocket.OPEN) {
//       this.ws.send(message);
//     } else {
//       console.error('WebSocket is not open. ReadyState: ' + this.ws.readyState);
//     }
//   }

//   disconnect() {
//     if (this.ws) {
//       this.ws.close();
//       console.log('WebSocket is disconnected now.');
//       this.stopHeartbeat();
//       this.stopHeartbeatResponseTimer();
//       this.ws = null;
//     }
//   }

//   startHeartbeat() {
//     this.heartbeatTimer = setInterval(() => {
//       if (this.ws.readyState === WebSocket.OPEN) {
//         console.log("开始心跳计时")
//         this.ws.send(JSON.stringify({ heartbeat: 1 }));
//         console.log(this.ws,'this.ws')
//         this.resetHeartbeatResponseTimer();
//       }
//     }, this.heartbeatInterval);
//   }

//   stopHeartbeat() {
//     console.log("停止心跳计时")
//     if (this.heartbeatTimer) {
//       clearInterval(this.heartbeatTimer);
//       this.heartbeatTimer = null;
//     }
//   }

//   resetHeartbeatResponseTimer() {
//     console.log('Heartbeat response timed out. Reconnecting...')
//     this.stopHeartbeatResponseTimer();
//     this.heartbeatResponseTimer = setTimeout(() => {
//       console.error('Heartbeat response timed out. Reconnecting...');
//       // this.reconnect();
//     }, this.heartbeatResponseTimeout);
//   }

//   stopHeartbeatResponseTimer() {
//     if (this.heartbeatResponseTimer) {
//       clearTimeout(this.heartbeatResponseTimer);
//       this.heartbeatResponseTimer = null;
//     }
//   }

//   handleVisibilityChange() {
//     if (document.visibilityState === 'visible') {
//       if (this.isLoggedIn && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
//         this.connect();
//       }
//     } else {
//       if (this.ws && this.ws.readyState === WebSocket.OPEN) {
//         this.disconnect();
//       }
//     }
//   }

//   handleBeforeUnload(event) {
//     if (this.ws) {
//       this.disconnect(); // Clean up WebSocket connection before unloading
//     }
//   }

//   get isLoggedIn() {
//     return this.store.state.user.isLoggedIn;
//   }

//   static getInstance(url, store) {
//     if (!WebSocketSingleton.instance) {
//       WebSocketSingleton.instance = new WebSocketSingleton(url, store);
//     }
//     return WebSocketSingleton.instance;
//   }
// }

// export default WebSocketSingleton;

class WebSocketSingleton {
  constructor(url, store) {
    if (WebSocketSingleton.instance) {
      return WebSocketSingleton.instance;
    }
    this.url = url;
    this.store = store;
    this.ws = null;
    this.heartbeatInterval = 4000;
    this.heartbeatTimer = null;


    this.store.watch(
      (state) => state.user.isLoggedIn,
      (isLoggedIn) => {
        if (isLoggedIn) {
          this.connect();
        } else {
          this.disconnect();
        }
      }
    );


    this.initEventListeners();
    WebSocketSingleton.instance = this;
  }


  async initEventListeners() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
  }




  async connect() {
    if (this.isLoggedIn && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
      // this.ws = new WebSocket(this.url);
      // this.ws.onopen = () => {
      //   console.log('WebSocket is connected.');
      //   this.sendInitialData();
      //   console.log('初始化.');
      //   this.startHeartbeat(); // 启动心跳包发送
      // };
      // this.ws.onmessage = (event) => {
      //   this.handleMessage(event);
      // };
      // this.ws.onclose = (event) => {
      //   console.log('WebSocket closed. Code:', event.code, 'Reason:', event.reason);
      //   this.stopHeartbeat(); // 停止心跳包发送
      //   if (event.code !== 1000) {
      //     console.error('Abnormal closure with code:', event.code);
      //     this.reconnect();
      //   }
      // };
      // this.ws.onerror = (error) => {
      //   console.error('WebSocket error:', error);
      //   this.stopHeartbeat(); // 停止心跳包发送
      // };
      try {
        await this.createWebSocket();
        await this.sendInitialData(); // Ensure initial data is sent after connection
        this.startHeartbeat(); // Start sending heartbeat messages
    } catch (error) {
        console.error('Error connecting to WebSocket:', error);
        throw error;
    }
    }
  }

  createWebSocket() {
    return new Promise((resolve, reject) => {
        this.ws = new WebSocket(this.url);
        this.ws.onopen = () => {
            console.log('WebSocket connected.');
            resolve(); // Resolve the promise when connection is successful
        };
        this.ws.onmessage = (event) => {
            this.handleMessage(event);
        };
        this.ws.onclose = (event) => {
            console.log('WebSocket closed. Code:', event.code, 'Reason:', event.reason);
            this.stopHeartbeat(); // Stop heartbeat when connection closes
            if (event.code !== 1000) {
                console.error('Abnormal closure, code:', event.code);
                this.reconnect(); // Reconnect if closed abnormally
            }
        };
        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.stopHeartbeat(); // Stop heartbeat on error
            reject(error); // Reject the promise on error
        };
    });
}

 async sendInitialData() {
    console.log('初始化');
    // const downloadIds='owbOj1AXcdawS9G7wDFoxNMqMpU2'
    // const downloadId = downloadIds;
    const downloadId = this.store.state.user.userInfomation.fb_uid;
    const downloadIdObject = { fb_uid: downloadId };
    console.log('downloadId', downloadId)
    this.ws.send(JSON.stringify(downloadIdObject));
  }

  async handleMessage(event) {
    // console.log('Message from server:', event.data);
    // const data = JSON.parse(event.data);
    // if (!data.hasOwnProperty('heartbeat')) {
    //   data.nowTime = Date.now();
    //   console.log('Message from server data:', data);
    //   this.store.commit('user/UPDATE_NOTIFICATION', data);
    // }
    try {
      console.log('Message from server:', event.data);
      const data = JSON.parse(event.data);

      if (!data.hasOwnProperty('heartbeat')) {
          data.nowTime = Date.now();
          console.log('Data from server:', data);
          // await this.updateNotifications(data);
          this.store.commit('user/UPDATE_NOTIFICATION', data);
      }
  } catch (error) {
      console.error('Error handling message:', error);
  }
  }

  async reconnect() {
    this.disconnect(); // Ensure existing connection is closed
    setTimeout(async () => {
        console.log('Reconnecting WebSocket...');
        try {
            await this.connect(); // Try to reconnect
        } catch (error) {
            console.error('Error reconnecting:', error);
        }
    }, 5000); // Reconnect after 5 seconds
}

  send(message) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(message);
    } else {
      console.error('WebSocket is not open. ReadyState: ' + this.ws.readyState);
    }
  }

  async disconnect() {
    if (this.ws) {
      this.ws.close();
      console.log('WebSocket is disconnected now.');
      this.stopHeartbeat(); // 停止心跳包发送
      this.ws = null;
    }
  }

  startHeartbeat() {
    this.heartbeatTimer = setInterval(() => {
      if (this.ws.readyState === WebSocket.OPEN) {
        console.log("Sending heartbeat.0..");
        this.ws.send(JSON.stringify({ heartbeat: 1 }));
        console.log("Sending heartbeat..1.");
      }
    }, this.heartbeatInterval);
  }

  stopHeartbeat() {
    console.log("Stopping heartbeat...");
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  }

  async handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      if (this.isLoggedIn && (!this.ws || this.ws.readyState === WebSocket.CLOSED)) {
        await this.connect();
      }
    } else {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.disconnect();
      }
    }
  }

  handleBeforeUnload(event) {
    if (this.ws) {
      this.disconnect(); // Clean up WebSocket connection before unloading
    }
  }

  get isLoggedIn() {
    return this.store.state.user.isLoggedIn;
  }

  static async getInstance(url, store) {
    if (!WebSocketSingleton.instance) {
      WebSocketSingleton.instance = new WebSocketSingleton(url, store);
      await WebSocketSingleton.instance.connect();
    }
    return WebSocketSingleton.instance;
  }
}

export default WebSocketSingleton;
