/* 主框架 */
export default {
  breadBrand:'Brand',
  brandName:'Brand',
  brandNameInput:'Please enter brand name',
  project:'Project',
  projectName:'Project',
  projectNameInput:'Please enter project name',
  addBrand:'Add Brand',
  projectNum:'Projects',
  softwareNum:'Software',
  currentlocal:'Current',
  index:'Home',
  creatTime:'Created',
  startTime:'Start Date',
  endTime:'End Date',
  to:'to',
  search:'Search',
  reset:'Reset',
  columnSetting:'Columns',
  updateTime:'Updated',
  moreActions:'More',
  edit:'Edit',
  details:'Details',
  delete:'Delete',
  push:'Push',
  subbrandNum:'Sub Projects',
  platform:'Platform',
  carLabel:'Applicable',
  description:'Info',
  desInput:'Please enter description',
  cancel:'Cancel',
  save:'Save',
  editBrand:'Edit Brand',
  confirmTitle:'Alert',
  confirmContent:'Are you sure you want to delete the selected brand?',
  confirmprojectContent:'Are you sure you want to delete the selected project?',
  confirmfileContent:'Are you sure you want to delete the selected file?',
  confirm:'Confirm',
  addProject:'Add Project',
  choose:'Please Select',
  editProject:'Edit Project',
  deleteSuc:'Delete Successful',
  deleteFal:'Delete Failed',
  addSuc:'Add Successful',
  addFal:'Add Failed',
  updateSuc:'Update Successful',
  updateFal:'Update Failed',
  exitBrand:'The brand name already exists, please enter another name',
  exitProject:'The project name already exists, please enter another name',
  brandVali:'Only require English, numbers & dashes, and the first letter must be capitalized',
  projectVali:'Only require English, numbers & dashes, and the first letter must be capitalized',
  pushing:'Pushing',
  pushSuc:'Push Successful',
  pushFal:'Push Failed',
  Asia:'Asia',
  China:'China',
  Korea:'Korea',
  Japan:'Japan',
  Singapore:'Singapore',
  Malaysia:'Malaysia',
  Europe:'Europe',
  UK:'United  kingdom',
  Germany:'Germany',
  France:'France',
  Netherlands:'Netherlands',
  NorthAmerica:'North America',
  US:'United States',
  Canada:'Canada',
  Mexico:'Mexico',
  Vietnam:'Vietnam',
  input:'Please enter content',
  file:'File',
  softName:'Software',
  softNames:'Software:',
  softnameInput:'Please enter software name',
  softwareType:'Type',
  stand:'Standard Ver',
  special:'Special Ver',
  updateType:'Update',
  subProject:'Sub',
  subProjects:'Sub:',
  download:'Download',
  subprojectInput:'Please enter sub project',
  hwVersion:'HW Ver',
  hwInput:'Please enter hardware version',
  softVersion:'SW Ver',
  softwareInput:'Please enter software version',
  saleCountry:'Country',
  expand:'Expand',
  pack:'Collapse',
  upload:'Upload File',
  downloading:' 1 software is presently being downloaded',
  more:'View More>> ',
  pushStatus:'Status',
  notPushed:'Standby',
  pushed:'Pushed',
  pushings:'Pushing',
  uploadSoft:'Upload Software',
  confirmMessage:'Please fill in the detailed information of the software before uploading',
  chooseFile:'Select File',
  fill:'Fill',
  fillSucess:'Filled in successfully',
  softwareSize:'Size:',
  softwareSizes:'Size',
  exportCountry:'Export Country',
  exportCountrys:'EXP Country:',
  ApplicableModels:'Applicable:',
  ApplicableModelss:'Applicable',
  chip:'Chip:',
  chips:'Chip',
  newField:'New Field',
  softwareDes:'Software Info',
  softwareDess:'About this Update:',
  finish:'OK',
  editFields:'Edit',
  uploadTime:'Upload:',
  uploadTimes:'Upload',
  downloadNum:'Downloads:',
  downloadNums:'Downloads',
  editedTime:'Updated:',
  downloadCenter:'Download Center',
  fromWeek:'From last week',
  trend:'Trend',
  downloadPlatform:'Download Platform',
  January:'Jan',
  February:'Feb',
  March:'Mar',
  April:'Apr',
  May:'May',
  June:'Jun',
  July:'Jul',
  August:'Aug',
  Sep:'Sep',
  October:'Oct',
  November:'Nov',
  December:'Dec',
  userName:'User',
  downloadProgress:'Download Progress',
  downloadTime:'Download Time',
  uploadSuc:'Upload successful',
  uploading:'Uploading',
  uploadErr:'Upload failed',
  breakpointResume:'Breakpoint resume!',
  skip:'This file already exists, skipping to the next file upload',
  noneFilled:'Incomplete information, please fill in all fields',
  maxFile:'Only 3 files can be selected for upload',
  maxAdd:'Up to 12 new entries can be added!',
  deleteUser:'Delete this content?',
  editSoftinfo:'Edit',
  userNums:'Users',
  downloadTotal:'Downloaded',
  softwareDetails:'Software Details',
  downloadArea:'Downloads By Region',
  phoneDownload:'Mobile Download Charts',
  perhourDownload:'Recent Downloads (Day)',
  year:'Year',
  month:'Month',
  day:'Day',
  softwareNO:'{name} NO:',
  keywords:'Please input keywords',
  please_input:"Please input",
  software_require:"First letter requires capital V or number.",
  input_newSubmodel:"Please input new submodel",
  submodelVali:'Only require English, numbers & dashes, and the first letter must be capitalized',
  submodelError:'This submodel already existed',
  input_newUpdateType:"Please input new type ",
  updateypeError:'This update type already existed ',
  input_newmodel:"Please input new model",
  add_submodel:"Add submodel",
  add_updatetype:"Add type",
  add_model:"Add model",
  choose_year:"Please choose year",
  choose_date:"Please choose date",
  nodata:"No data",
  addURL:"Add URL",
};
